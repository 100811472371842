import axiosInstance from '../AxiosInstance';

export function getAdminOrders() {
  return axiosInstance.get(`/api/management/orderings`);
}
export function getManagerOrders() {
  return axiosInstance.get(`/api/admin/orderings`);
}

export function getDeliveryOrders() {
  return axiosInstance.get(`/api/deliveries`);
}

export function getMyDeliveryOrders(page) {
  return axiosInstance.get(`/api/deliveries/my_deliveries`, {params: {page: page}});
}

export function getRestaurantOrders(page) {
  return axiosInstance.get(`/api/management/orderings/restaurant_orders`, {params: {page: page}});
}

export function getDeliveryOrder(id, isRide) {
  if (isRide) {
    return axiosInstance.get(`/api/rides/${id}`);
  } else {
    return axiosInstance.get(`/api/deliveries/${id}`);
  }
}

export function setOrderStatus(order, action) {
  const params = {ordering: {time: order.time/1000, status: action}};
  return axiosInstance.put(`/api/management/orderings/${order.id}`, params);
}

export function setManagerOrderStatus(order, action) {
  const params = {ordering: {time: order.time/1000, status: action}};
  return axiosInstance.put(`/api/admin/orderings/${order.id}`, params);
}

export function setManagerOrder(order, parameters) {
  const params = {ordering: parameters};
  return axiosInstance.put(`/api/admin/orderings/${order.id}`, params);
}

export function changeOrder(order, parameters) {
  const params = {ordering: parameters};
  return axiosInstance.put(`/api/management/orderings/${order.id}`, params);
}

export function handleDeliveryUser(order, take) {
  if (order.ride) {
    const params = {ride: {take_order: take}};
    return axiosInstance.put(`/api/rides/${order.id}`, params);
  } else {
    const params = {ordering: {take_order: take}};
    return axiosInstance.put(`/api/deliveries/${order.id}`, params);
  }
}

export function deliveryStatusChange(order, method) {
  if (order.ride) {
    const statuses = {took: 'arrive', waiting: 'start_ride', riding: 'complete'};
    return axiosInstance.put(`/api/rides/${order.id}/update_status`, { status: method || statuses[order.status] });
  } else {
    const statuses = {ready: 'delivering', on_delivery: 'done'};
    return axiosInstance.put(`/api/deliveries/${order.id}/update_status`, { status: statuses[order.status] });
  }
}

export function savePhoneOrder(parameters) {
  const params = {phone: parameters.phone, amount: parameters.amount, when_ready: true, address: parameters.address,
    time: (Date.now() + (parameters.time * 60000))/1000, payment_type: parameters.payment_type};
  return axiosInstance.post(`/api/management/orderings/create_delivery`, params);
}

export function createOrder(dishes, params) {
  const orders = dishes.map((dish) => {
    const mods = Object.values(!!dish.selectedMods ? dish.selectedMods : {});
    if (!!dish.selectedVariation) {
      return {id: dish.id, quantity: dish.quantity, dish_variation_id: dish.selectedVariation.id, mods: mods}
    } else {
      return {id: dish.id, quantity: dish.quantity, mods: mods}
    }
  });
  const postData = Object.assign(params, {orders: orders});
  return axiosInstance.post(`/api/management/orderings`, postData);
}

export function makeOperatorOrder(restaurant_id, dishes, params) {
  const orders = dishes.map((dish) => {
    const mods = Object.values(!!dish.selectedMods ? dish.selectedMods : {});
    if (!!dish.selectedVariation) {
      return {id: dish.id, quantity: dish.quantity, dish_variation_id: dish.selectedVariation.id, mods: mods}
    } else {
      return {id: dish.id, quantity: dish.quantity, mods: mods}
    }
  });
  const postData = { restaurant_id, orders: orders, order_type: params.selectedOrderType,
    time: new Date(params.time).getTime()/1000,
    description: params.description, when_ready: params.selectedReadyType === 'when_ready',
    persons: params.selectedOrderType === 'to_visit' ? params.persons : null,
    phone: params.phone,
    first_name: params.first_name,
    cutleries: params.cutleries
  };
  if (params.selectedOrderType === 'delivery') {
    Object.assign(postData, {delivery_point: params.delivery_point, delivery_address: params.delivery_address})
  }
  return axiosInstance.post(`/api/admin/orderings`, postData);
}

export function refundOrder(order) {
  const params = {id: order.id};
  return axiosInstance.post(`/api/payments/refund`, params);
}

export function refundDishOrder(orderId, dishOrderId) {
  const params = {dish_order_id: dishOrderId};
  return axiosInstance.post(`/api/management/orderings/${orderId}/refund_dish_order`, params);
}

export function handleWaiterRequest(table) {
  return axiosInstance.put(`/api/management/tables/${table.id}/waiter_request`);
}

export function savePayCheck(table, payCheck) {
  let params = {};
  if (!!payCheck.id) {
    if (!!table.pay_check_item_name && table.pay_check_item_amount) {
      params = {pay_check: {
          pay_check_items_attributes: [{name: table.pay_check_item_name, amount: table.pay_check_item_amount}]}
      };
    } else {
      params = {pay_check: {amount: payCheck.amount}};
    }
    return axiosInstance.put(`/api/management/pay_checks/${payCheck.id}`, params);
  } else {
    params = {pay_check: {amount: table.check_amount, table_id: table.id}};
    return axiosInstance.post(`/api/management/pay_checks`, params);
  }
}

export function closePayCheck(payCheckId, action, table) {
  let params = {};
  if (action === 'approve') {
    params = {pay_check: {action: action, amount: table.pay_check.amount}}
  } else {
    params = {pay_check: {action: action}}
  }
  return axiosInstance.put(`/api/management/pay_checks/${payCheckId}/update_status`, params);
}

export function destroyPayCheckItem(id) {
  return axiosInstance.delete(`/api/management/pay_check_items/${id}`);
}

export function getRestaurantPayCheck(payCheckId) {
  return axiosInstance.get(`/api/management/pay_checks/${payCheckId}`);
}

export function createOrderMessage(orderId, message) {
  const params = {message: {text: message}, order_id: orderId};
  return axiosInstance.post(`/api/order_messages`, params);
}

export function deletePayCheck(payCheck) {
  return axiosInstance.delete(`/api/management/pay_checks/${payCheck.id}`);
}

export function removePayCheck(payCheck) {
  return axiosInstance.delete(`/api/management/pay_checks/${payCheck.id}/delete`);
}

export function getRestaurantPayChecks(page, dates, pType) {
  const params = {params: {page: page, dates: dates, payment_type: pType}};
  return axiosInstance.get(`/api/management/pay_checks`, params);
}

export function cancelDishOrder(dishOrderId, withStopList) {
  const params = {params: {stop_list: withStopList}};
  return axiosInstance.delete(`/api/management/dish_orderings/${dishOrderId}`, params);
}

export function savePayCheckItem(id, count) {
  const params = {pay_check_item: {quantity: count}};
  return axiosInstance.put(`/api/management/pay_check_items/${id}`, params);
}

export function postPayCheckItem(parameters, payCheckId) {
  const params = {pay_check_item: {name: parameters.pay_check_item_name, price: parameters.pay_check_item_price,
    pay_check_id: payCheckId}};
  return axiosInstance.post(`/api/management/pay_check_items`, params);
}

export function getTablePayCheck(table) {
  const params = {params: {table_id: table.id}};
  return axiosInstance.get(`/api/management/pay_checks/table`, params);
}

export function printOrderToKitchen(id) {
  return axiosInstance.post(`/api/management/orderings/${id}/print_kitchen`);
}