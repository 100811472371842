import swal from "sweetalert";
import { toast } from 'react-toastify';
import React from "react";

const Error = ({ message }) => (
  <div>
    <h4 className='d-flex align-items-center justify-content-center'>
      <span className='fs-32 mr-2'>{String.fromCodePoint('0x26A0')}</span>
      <span>Опаньки</span>
    </h4>
    <span>{message}</span>
  </div>
);

const Alert = ({ type, header, message }) => (
  <div>
    <h4 className='d-flex align-items-center justify-content-center'>
      {/*<span className='fs-32 mr-2'>{String.fromCodePoint('0x26A0')}</span>*/}
      <span>{header}</span>
    </h4>
    <span>{message}</span>
  </div>
);

export function handleError(errors, resp) {
  if (!!resp && resp.status === 401) {
    swal({
      title: 'Перейти на сторінку входу?',
      text: 'Щоб здійснити дію потрібно увійти під власним профілем',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Так',
          visible: true
        },
        confirm: {
          text: 'Ні',
          visible: true
        },
      },
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        window.location.href = '/login';
      }
    })
  } else {
    toast.error(<Error message={!!errors ? errors.join(', ') : 'Невідома помилка'}/>,
      {hideProgressBar: true, autoClose: 5000})
  }
}

export function handleAlert(type, header, message, time) {
  if (type === 'default') {
    toast(<Alert message={message} type={type} header={header}/>, {hideProgressBar: true, autoClose: time || 2000})
  } else {
    toast[type](<Alert message={message} type={type} header={header}/>, {hideProgressBar: true, autoClose: time || 1000})
  }
}