import React, {lazy, useState, useEffect} from 'react'
/// React router dom
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
/// Css
import 'leaflet/dist/leaflet.css';
import '../scss/index.scss'
import "react-toggle/style.css"
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import 'react-phone-input-2/lib/style.css'
import 'react-chat-elements/dist/main.css'
import "react-datepicker/dist/react-datepicker.css";

/// Layout
import Nav from './layouts/nav'
import BottomNav from './layouts/nav/BottomNav';

import { checkAutoLogin } from '../services/AuthService';

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import Notification from "../firebaseNotification/Notification";
import ActiveRides from "./components/Public/Rides/ActiveRides";
// /// App
const PublicHome = lazy(() => import('./components/Public/Home'));
const Restaurant = lazy(() => import('./components/Public/Restaurants/show'));
const Profile = lazy(() => import('./components/Public/Profile'));
const Checkout = lazy(() => import('./components/Public/Checkout'));
const MenuIndex = lazy(() => import('./components/Public/Menu/index'));
const Orders = lazy(() => import('./components/Public/Orders/index'));
const Order = lazy(() => import('./components/Public/Orders/show'));
const Install = lazy(() => import('./components/Public/Install'));
const ActiveOrders = lazy(() => import('./components/Public/Orders/ActiveOrders'));
const Company = lazy(() => import('./components/Public/Company'));
const RideRequest = lazy(() => import('./components/Public/RideRequest'));
const Ride = lazy(() => import('./components/Public/Rides/show'));

// Admin
const DeliveryUsers = lazy(() => import('./components/admin/DeliveryUsers'));
const AdminSettings = lazy(() => import('./components/admin/AdminSettings'));
const Dashboard = lazy(() => import('./components/admin/Dashboard/Dashboard'));
const DeliveryOrders = lazy(() => import('./components/admin/Deliveries/DeliveryOrders'));
const DeliveryOrder = lazy(() => import('./components/admin/Deliveries/show'));
const MyDeliveryOrders = lazy(() => import('./components/admin/Deliveries/index'));
const RestaurantOrders = lazy(() => import('./components/admin/RestaurantOrders'));
const PayChecks = lazy(() => import('./components/admin/PayChecks'));
const Settings = lazy(() => import('./components/admin/Settings/Settings'));
const GeneralSettings = lazy(() => import('./components/admin/Settings/General'));
const ServicesSettings = lazy(() => import('./components/admin/Settings/Services'));
const MenuSettings = lazy(() => import('./components/admin/Settings/Menu'));
const ScheduleSettings = lazy(() => import('./components/admin/Settings/CategoriesHours'));
const TablesSettings = lazy(() => import('./components/admin/Settings/Tables'));
const StuffSettings = lazy(() => import('./components/admin/Settings/Stuff'));
const StopList = lazy(() => import('./components/admin/StopList'));
const Support = lazy(() => import('./components/admin/Support'));
const Analytics = lazy(() => import('./components/admin/Analytics'));
const RestaurantGuests = lazy(() => import('./components/admin/RestaurantGuests'));
const ManagerOrders = lazy(() => import('./components/admin/ManagerOrders'));

//pages
const AuthorizeAccess = lazy(() => import('../jsx/pages/AuthorizeAccess'));
const SignUp = lazy(() => import('../jsx/pages/Registration'));
const Terms = lazy(() => import('../jsx/pages/Terms'));
const Policy = lazy(() => import('../jsx/pages/Policy'));
const About = lazy(() => import('../jsx/pages/About'));
const ForgotPassword = lazy(() => import('../jsx/pages/ForgotPassword'));
const Password = lazy(() => import('../jsx/pages/Password'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('../jsx/pages/Login')), 100);
  });
});
const RivneLanding = lazy(() => import('./pages/rivne/landing'));

const Markup = (props) => {
  const dispatch = useDispatch();
  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];
  let pagePath = path.split('-').includes('page');
  const [activeEvent, setActiveEvent] = useState(!path);
  const storeAuth = useSelector(store => store.auth);

  useEffect(() => {
    if (adminRoutes.map((r) => r.url).includes(path) || authRoutes.map((r) => r.url).includes(path)) {
      checkAutoLogin(dispatch, props.history);
    }
  }, [dispatch, props.history]);

  const loginRoutes = [
    { url: "login", component: Login },
    { url: "register", component: SignUp },
    { url: "forgot-password", component: ForgotPassword },
    { url: "password", component: Password },
    { url: "terms", component: Terms },
    { url: "privacy-policy", component: Policy },
    { url: "about", component: About },
    { url: 'rivne', component: RivneLanding }
  ];

  const routes = [
    { url: "/", component: PublicHome },
    { url: 'restaurants/:id', component: Restaurant },
    { url: 'r/:id', component: Restaurant },
    { url: 'checkout/:id', component: Checkout },
    { url: 'check-out/:id', component: Checkout },
    { url: 'restaurants/:id/menu', component: MenuIndex },
    { url: 'r/:id/menu', component: MenuIndex },
    { url: 'menu/:id', component: MenuIndex },
    { url: 'install', component: Install },
    { url: 'share_order/:id', component: Order },
    { url: 'access', component: AuthorizeAccess },
    { url: 'taxi', component: RideRequest },
    { url: 'rides/:id', component: Ride },
    //{ url: 'c/:id', component: Company },
  ];

  const authRoutes = [
    { url: 'profile', component: Profile },
    { url: 'orders', component: Orders },
    { url: 'orders/:id', component: Order },
  ];

  const adminRoutes = [
    { url: 'delivery_users', component: DeliveryUsers },
    { url: 'admin-settings', component: AdminSettings },
    { url: 'dashboard', component: Dashboard },
    { url: 'deliveries', component: DeliveryOrders },
    { url: 'deliveries/:id', component: DeliveryOrder },
    { url: 'driver_ride/:id', component: DeliveryOrder },
    { url: 'my-deliveries', component: MyDeliveryOrders },
    { url: 'restaurant-orders', component: RestaurantOrders },
    { url: 'paychecks', component: PayChecks },
    { url: 'settings', component: Settings },
    { url: 'settings/general', component: GeneralSettings },
    { url: 'settings/services', component: ServicesSettings },
    { url: 'settings/menu', component: MenuSettings },
    { url: 'settings/schedule', component: ScheduleSettings },
    { url: 'settings/tables', component: TablesSettings },
    { url: 'settings/stuff', component: StuffSettings },
    { url: 'stop_list', component: StopList },
    { url: 'support', component: Support },
    { url: 'analytics', component: Analytics },
    { url: 'restaurant_guests', component: RestaurantGuests },
    { url: 'manager_orders', component: ManagerOrders },
  ];

  return (
    <>
      { loginRoutes.map((r) => r.url).includes(path) ?
        <Switch>
          { loginRoutes.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} component={data.component}/>))}
        </Switch>
      :
        <>
          <div style={{marginBottom: 50}} id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            { !pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />)}
            <div className={` ${!path && activeEvent ? 'rightside-event' : ''} ${!pagePath ? 'content-body' : ''}`}>
              <div className={`${!pagePath ? 'container-fluid' : ''}`} style={{ minHeight: window.screen.height - 506 }}>
                <Switch>
                  <Route exact path={`/c/novograd_delivery`}
                         render={(props) =>
                            <PublicHome
                              {...props}
                              location={{landing_location: [50.597557, 27.599731]}}
                            />
                         }
                  />
                  { props.isAuthenticated ?
                    <>
                      { routes.map((data, i) => (
                        <Route key={i} exact path={`/${data.url}`} component={data.component}/>))}
                      { adminRoutes.map((data, i) => (
                        <Route key={i} exact path={`/${data.url}`} component={data.component}/>))}
                      { authRoutes.map((data, i) => (
                        <Route key={i} exact path={`/${data.url}`} component={data.component}/>))}
                    </>
                  :
                    <>
                      { routes.map((data, i) => (
                        <Route key={i} exact path={`/${data.url}`} component={data.component}/>))}
                    </>}
                </Switch>
              </div>
            </div>
            {/*{!pagePath && <Footer />}*/}
          </div>
        <ScrollToTop />
        <BottomNav/>
      </>}

      <ActiveOrders/>
      <ActiveRides/>
      {!!storeAuth.auth.userData && storeAuth.auth.userData.working && <Notification/>}
    </>
  )
};

export default Markup
