import React from "react";
import {Link} from 'react-router-dom';
import {ActionCableConsumer} from "react-actioncable-provider";
import {handleAlert} from "../../../../services/AlertService";
import { useTranslation } from 'react-i18next';

const ActiveRides = () => {
  const { t } = useTranslation();

  function activeOrders() {
    return !!localStorage.getItem('activeRide') && !window.location.pathname.includes('rides');
  }

  function activeRide() {
    return localStorage.getItem('activeRide');
  }

  function changeOrderStatus(data) {
    handleAlert('default', t(`orderStatuses.${data.order.status}`), data.message);
    if (data.order.status === 'completed' || data.order.status === 'canceled') {
      localStorage.removeItem('activeRide');
    }
  }

  return (
    <>
      { activeOrders() &&
        <>
          <ActionCableConsumer channel={{channel: 'RideChannel', room: activeRide()}}
                               onReceived={(data) => changeOrderStatus(data)}
          />
          <div className='active-ride opacity'>
            <Link to={`/rides/${activeRide()}`}>
              <span className='emoji mr-2' role="img" aria-label={'label'}>
                {String.fromCodePoint('0x1F695')}
              </span>
            </Link>
          </div>
        </>}
    </>
  );
};

export default ActiveRides;